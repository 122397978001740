import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import { Provider } from 'react-redux';
import { store } from './Store/Store';
import { Auth0Provider } from '@auth0/auth0-react';
import history, { getConfig } from "./history";
import { ApolloContextProvider } from './ApolloContext'; // Ensure this is correct

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const onRedirectCallback = (appState: any) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const config = getConfig();
const providerConfig = {
  domain: "byronaldebaran.us.auth0.com",
  clientId: "N6YzK0IxdJAp4LIcs2LpKlhgyvd11l3w",
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: 'https://burodevales.mx/home',
    ...(config.audience ? { audience: config.audience } : null),
  },
};

root.render(
  <ApolloContextProvider>
    <Provider store={store}>
      <Auth0Provider {...providerConfig}>
        {/* <ApolloClientWrapper> */}
          <App />
        {/* </ApolloClientWrapper> */}
      </Auth0Provider>
    </Provider>
  </ApolloContextProvider>
);
