import React, { createContext, useContext, ReactNode, useState, useEffect } from 'react';
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';

// Funciones para obtener las URLs de los microservicios
export const GetServerUrlEmpresas = (): string => 
  window.location.origin === 'http://localhost:3000' ? "http://localhost:5000/empresas" : "https://web.burodevales.mx/empresas";

export const GetServerUrlAdmin = (): string => 
  window.location.origin === 'http://localhost:3000' ? "http://localhost:5000/administracion/" : "https://web.burodevales.mx/administracion/";

export const GetServerUrlMesaAyuda = (): string => 
  window.location.origin === 'http://localhost:3000' ? "http://localhost:5000/mesaAyuda/" : "https://web.burodevales.mx/mesaAyuda/";

export const GetServerUrlConsultaClientes = (): string => 
  window.location.origin === 'http://localhost:3000' ? "http://localhost:5000/notificationHub" : "https://web.burodevales.mx/notificationHub";

export const GetServerUrlCapacitacion = (): string => 
  window.location.origin === 'http://localhost:3000' ? "http://localhost:5000/capacitacion" : "https://web.burodevales.mx/capacitacion";

interface ApolloContextType {
  setClient: (clientName: string) => void;
  currentClient: ApolloClient<any>;
}

const ApolloContext = createContext<ApolloContextType | undefined>(undefined);

// Crear cliente de Apollo
const createClient = (clientName: string) => {
  let uri: string;

  switch (clientName) {
    case 'empresas':
      uri = GetServerUrlEmpresas();
      break;
    case 'administracion':
      uri = GetServerUrlAdmin();
      break;
    case 'mesaAyuda':
      uri = GetServerUrlMesaAyuda();
      break;
    case 'notificaciones':
      uri = GetServerUrlConsultaClientes();
      break;
    case 'capacitacion':
      uri = GetServerUrlCapacitacion();
      break;
    default:
      uri = GetServerUrlEmpresas(); // URL por defecto
  }

  return new ApolloClient({
    uri,
    cache: new InMemoryCache(),
  });
};

export const ApolloContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [clientName, setClientName] = useState<string>('empresas'); // Estado para el nombre del cliente
  const [currentClient, setCurrentClient] = useState<ApolloClient<any>>(createClient(clientName)); // Estado para el cliente actual

  // Actualiza el cliente cuando el nombre cambia
  useEffect(() => {
    const newClient = createClient(clientName);
    setCurrentClient(newClient);
  }, [clientName]);

  const setClient = (name: string) => {
    setClientName(name); // Cambia el nombre del cliente
  };

  return (
    <ApolloContext.Provider value={{ setClient, currentClient }}>
      <ApolloProvider client={currentClient}>
        {children}
      </ApolloProvider>
    </ApolloContext.Provider>
  );
};

export const useApolloClient = (clientName: string) => {
  const context = useContext(ApolloContext);
  if (!context) {
    throw new Error("useApolloClient must be used within an ApolloContextProvider");
  }

  // Cambia el cliente usando un efecto
  useEffect(() => {
    context.setClient(clientName);
  }, [clientName]);

  return context.currentClient; // Retorna el cliente actual
};
