import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SetSelectedColorPayload {
  color: string;
}

interface SetPhoneNumberPayload {
  phoneNumber: any;
}

interface LoginPayload {
  isAuthenticated?: boolean;
  user?: string;
  roles?: any[];
  idRol?: any;
  token?: string;
  phoneNumber?: string;
  idUsuario?: string; // Agregado
  primerIngreso?: boolean; // Agregado
}

interface AuthState {
  isAuthenticated: boolean;
  user: string | null;
  roles: any[];
  idRol: any;
  token: string | null;
  selectedColor: string;
  phoneNumber: string;
  idUsuario: string | null; // Agregado
  primerIngreso: boolean; // Agregado
}

const initialState: AuthState = {
  isAuthenticated: localStorage.getItem('isAuthenticated') === 'true',
  user: localStorage.getItem('user') || null,
  roles: localStorage.getItem('roles') ? JSON.parse(localStorage.getItem('roles')!) : [],
  idRol: localStorage.getItem('idRol') || null,
  token: localStorage.getItem('token') || null,
  selectedColor: '#011730',
  phoneNumber: localStorage.getItem('phoneNumber') || '',
  idUsuario: localStorage.getItem('idUsuario') || null, // Agregado
  primerIngreso: localStorage.getItem('primerIngreso') === 'true' // Agregado
};

// Slice de Redux
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {

    login(state, action: PayloadAction<any>) {
      const { isAuthenticated, user, roles, idRol, token, phoneNumber, idUsuario, primerIngreso } = action.payload;
      if (isAuthenticated !== undefined) state.isAuthenticated = isAuthenticated;
      if (user !== undefined) state.user = user;
      if (roles !== undefined) state.roles = roles;
      if (idRol !== undefined) state.idRol = idRol;
      if (token !== undefined) state.token = token;
      if (phoneNumber !== undefined) state.phoneNumber = phoneNumber;
      if (idUsuario !== undefined) state.idUsuario = idUsuario; // Agregado
      if (primerIngreso !== undefined) state.primerIngreso = primerIngreso; // Agregado

      // Almacenar en localStorage
      if (isAuthenticated !== undefined) localStorage.setItem('isAuthenticated', String(isAuthenticated));
      if (user !== undefined) localStorage.setItem('user', user);
      if (roles !== undefined) localStorage.setItem('roles', JSON.stringify(roles));
      if (idRol !== undefined) localStorage.setItem('idRol', String(idRol));
      if (token !== undefined) localStorage.setItem('token', token);
      if (phoneNumber !== undefined) localStorage.setItem('phoneNumber', phoneNumber);
      if (idUsuario !== undefined) localStorage.setItem('idUsuario', idUsuario); // Agregado
      if (primerIngreso !== undefined) localStorage.setItem('primerIngreso', String(primerIngreso)); // Agregado
    },

    setIsAuthenticated(state, action: PayloadAction<boolean>) {
      state.isAuthenticated = action.payload;
      localStorage.setItem('isAuthenticated', String(action.payload));
    },
    
    logout(state) {
      state.isAuthenticated = false;
      state.user = null;
      state.roles = [];
      state.idRol = null;
      state.token = null;
      state.phoneNumber = '';
      state.idUsuario = null; // Agregado
      state.primerIngreso = false; // Agregado

      // Limpiar localStorage
      localStorage.removeItem('isAuthenticated');
      localStorage.removeItem('user');
      localStorage.removeItem('roles');
      localStorage.removeItem('idRol');
      localStorage.removeItem('token');
      localStorage.removeItem('phoneNumber');
      localStorage.removeItem('idUsuario'); // Agregado
      localStorage.removeItem('primerIngreso'); // Agregado
    },
    
    setSelectedColor(state, action: PayloadAction<SetSelectedColorPayload>) {
      state.selectedColor = action.payload.color;
    },
    
    setPhoneNumber(state, action: PayloadAction<SetPhoneNumberPayload>) {
      state.phoneNumber = action.payload.phoneNumber;
      localStorage.setItem('phoneNumber', action.payload.phoneNumber);
    },
  },
});

// Exporta acciones y reducer
export const { login, logout, setIsAuthenticated, setSelectedColor, setPhoneNumber } = authSlice.actions;
export default authSlice.reducer;
