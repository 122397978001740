import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

export default history;
export const getConfig = () => {
    return {
      audience: "https://byronaldebaran.us.auth0.com/api/v2/",
      // Puedes añadir otras configuraciones aquí
    };
  };